import { React } from "react";
import NavBar from "./NavBar";
import Landing from "./Landing";
import About from "./About";
import Experience from "./Experience";
import Contact from "./Contact";
// import SocialLinks from "./SocialLinks";
import { ReactLenis } from 'lenis/react'
// import { useLocation } from "react-router-dom";
import Projects from "./Projects";
// import { Name } from "./Name";



const Main = ({ setCircleSize }) => {


  return (
    <ReactLenis
      options={{
        duration: 1.5, // Duration of the scroll effect
        smoothTouch: true, // Enable smooth scrolling on touch devices
        direction: "vertical", // Scroll direction
      }}
      root>
      <div className="bg-[#1a1a1a]">
        <NavBar setCircleSize={setCircleSize} />
        <Landing setCircleSize={setCircleSize} />
        <About />
        <Projects setCircleSize={setCircleSize} />
        <Experience />
        <Contact />
        {/* <SocialLinks /> */}
      </div>
    </ReactLenis >
    // <Name/>
  );
};

export default Main;
