import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import TagSphere from "./TagSphere";
import { FiExternalLink } from "react-icons/fi";
// import Contact from "./Contact";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";

gsap.registerPlugin(ScrollTrigger);

const Experience = () => {
  const titleRef = useRef(null);
  const lineRef = useRef(null);

  useEffect(() => {
    // Title animation
    // eslint-disable-next-line
    const split = new SplitType(titleRef.current, { types: "chars" });
    gsap.set(".char", { y: 50, opacity: 0 });
    gsap.to(".char", {
      scrollTrigger: {
        trigger: titleRef.current,
        start: "top bottom",
        toggleActions: "restart none none reset"
      },
      y: 0,
      opacity: 1,
      stagger: 0.03,
      duration: 0.5,
      ease: "power2.out"
    });

    // Single SVG Line animation
    gsap.to(lineRef.current, {
      strokeDashoffset: 0,
      ease: "none",
      scrollTrigger: {
        trigger: lineRef.current,
        start: "top 90%",
        end: "bottom 50%",
        scrub: true,
      }
    });

  }, []);

  const skills = [
    "HTML",
    "CSS",
    "MySql",
    "JavaScript",
    "TypeScript",
    "TailwindCSS",
    "SASS",
    "Express",
    "MongoDB",
    "SQL",
    "React",
    "Angular",
    "Node.js",
    "AWS",
    "Postman",
    "ES6",
    "C/C++",
    "Python",
    "Git",
    "GitHub",
    "Postgres",
    "RESTful API"
  ];

  const options = {
    radius: window.innerWidth <= 768 ? 150 : 200,
    maxSpeed: 0.7
  };

  return (
    <div className="flex flex-col">
      <div      
        name="experience"
        className="bg-[#1a1a1a] max-w-screen min-h-screen lg:h-[140vh] flex my-10 z-10"
      >
        <div className="p-4 flex flex-col text-neutral-300 gap-32 mx-auto my-auto">
          <div className="text-center">
            <p ref={titleRef} className="text-4xl font-mulish inline">
              EXPERIENCE
            </p>
          </div>  

          <div className="flex flex-col lg:flex-row mx-auto lg:gap-32">
            <div className="flex flex-row gap-4 font-mulish">
              <div className="flex flex-col items-start">
                <div className="flex flex-col items-center">
                  <svg width="16" height="280" className="overflow-visible">
                    <path
                      ref={lineRef}
                      d="M8 0 V 280"
                      stroke="#ffffff"
                      strokeWidth="2"
                      fill="none"
                      strokeDasharray="280"
                      strokeDashoffset="280"
                    />
                    {/* Circle at 100px from top */}
                    <circle
                      cx="8"
                      cy="100"
                      r="6"
                      fill="#ffffff"
                      stroke="#ffffff"
                      strokeWidth="2"
                    />
                    {/* Circle at 150px from top */}
                    <circle
                      cx="8"
                      cy="250"
                      r="6"
                      fill="#ffffff"
                      stroke="#ffffff"
                      strokeWidth="2"
                    />
                  </svg>
                </div>
              </div>
              <div className="md:min-w-[32rem]">
                <div className="flex flex-row mt-[5rem] justify-between border-b-[1px] border-[#292929] pb-2 gap-2">
                  <a href="https://therightdoctors.com">
                    <motion.h3 
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{ delay: 0.5, duration: 1.2 }}
                      viewport={{ once: true, amount: 0.5 }}
                      className="text-md lg:text-2xl hover:text-cyan-600"
                    >
                      Full-Stack Developer
                    </motion.h3>
                    <motion.h3 
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{ delay: 0.5, duration: 1.2 }}
                      viewport={{ once: true, amount: 0.5 }}
                      className="text-sm lg:text-xl hover:text-cyan-600 flex flex-row items-center gap-2"
                    >
                      TheRightDoctors.com <FiExternalLink />
                    </motion.h3>
                  </a>
                  <div>
                    <h3 className="text-sm lg:text-lg">Telangana, India</h3>
                  </div>
                </div>
                <div className="flex flex-row mt-[5rem] justify-between border-b-[1px] border-[#303030] pb-2">
                  <a href="https://team-srm-hackathon.vercel.app/">
                    <motion.h3 
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{ delay: 0.5, duration: 1.2 }}
                      viewport={{ once: true, amount: 0.5 }}
                      className="text-md lg:text-2xl hover:text-cyan-600"
                    >
                      Full-Stack Developer
                    </motion.h3>
                    <motion.h3 
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{ delay: 0.5, duration: 1.2 }}
                      viewport={{ once: true, amount: 0.5 }}
                      className="text-sm lg:text-xl hover:text-cyan-600 flex flex-row items-center gap-2"
                    >
                      Team SRM Hackathon <FiExternalLink />
                    </motion.h3>
                  </a>
                  <div>
                    <h3 className="text-sm lg:text-lg">Chennai, India</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center mt-24 ml-2">
              <TagSphere texts={skills} options={options} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;