import React, { useState, useRef, useCallback } from 'react';

const DropMailButton = () => {
    const [isHovering, setIsHovering] = useState(false);
    const buttonRef = useRef(null);
    const [coords, setCoords] = useState({ x: 0, y: 0 });
    
    // Throttle mouse move updates for better performance
    const handleMouseMove = useCallback((e) => {
        const button = buttonRef.current;
        if (!button) return;
        
        // Get button dimensions once
        const { left, top } = button.getBoundingClientRect();
        
        // Use transform3d to trigger GPU acceleration
        requestAnimationFrame(() => {
            setCoords({
                x: e.clientX - left,
                y: e.clientY - top
            });
        });
    }, []);

    const handleMouseEnter = useCallback((e) => {
        const button = buttonRef.current;
        if (!button) return;
        
        const { left, top } = button.getBoundingClientRect();
        setCoords({
            x: e.clientX - left,
            y: e.clientY - top
        });
        setIsHovering(true);
    }, []);

    const handleMouseLeave = useCallback(() => {
        setIsHovering(false);
    }, []);

    return (
        <a
            ref={buttonRef}
            onMouseMove={handleMouseMove}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="px-6 lg:px-10 lg:max-w-[250px] max-w-[200px] py-4 lg:py-5 cursor-none 
                       rounded-full relative block overflow-hidden uppercase border hover:border-[#1c1c1c]
                       border-[#b6b8b7] bg-[#1c1c1c] text-white hover:text-black group text-center"
            href="https://mail.google.com/mail/?view=cm&fs=1&to=aakashpatel315@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
        >
            <span className="relative z-20">Drop an email</span>
            <span
                className="absolute block rounded-full bg-[#b6b8b7] will-change-transform will-change-[width,height] z-10"
                style={{
                    left: coords.x,
                    top: coords.y,
                    transform: `translate3d(-50%, -50%, 0)`,
                    width: isHovering ? '125%' : '0%',
                    height: isHovering ? '125%' : '0%',
                    transition: 'width 350ms ease-out, height 350ms ease-out'
                }}
            />
        </a>
    );
};

export default DropMailButton;