import React, { useState, useEffect } from 'react';
import { IoHeadset } from "react-icons/io5";

const AllLetters = ['A','आ','诶','அ','あ','a','ㅏ',<IoHeadset />];

const Loading = () => {
    const [count, setCount] = useState(0);
    const [loadingComplete, setLoadingComplete] = useState(false); // Track loading completion
    const [moveOut, setMoveOut] = useState(false); // Track the x-axis translation

    useEffect(() => {
        const timer = setInterval(() => {
            setCount(prevCount => (prevCount + 1) % 8);
        }, 250);

        // Set loading complete after 3 seconds (for example)
        const loadingTimeout = setTimeout(() => {
            setMoveOut(true); // Trigger the move-out animation
            setTimeout(() => setLoadingComplete(true), 1700); // After animation, complete loading
        }, 2000); // Adjust this time to the duration of the loading

        return () => {
            clearInterval(timer);
            clearTimeout(loadingTimeout);
        };
    }, []);

    if (loadingComplete) {
        return null; // Remove loading component from the DOM after it's complete
    }

    return (
        <div className="fixed h-screen w-full flex flex-row bg-transparent justify-center font-bold items-center z-[90]">
            <div className={`flex-1 h-full w-1/4 bg-[#f2b980] transition-transform duration-[1200ms] ${moveOut ? 'translate-y-full' : 'translate-y-0'}`}></div>
            <div className={`flex-1 h-full w-1/4 bg-[#f2b980] transition-transform duration-[1700ms] ${moveOut ? 'translate-y-full' : 'translate-y-0'}`}></div>
            <div className={`flex-1 h-full w-1/4 bg-[#f2b980] transition-transform duration-[2200ms] ${moveOut ? 'translate-y-full' : 'translate-y-0'}`}></div>
            <div className={`flex-1 h-full w-1/4 bg-[#f2b980] transition-transform duration-[2700ms] ${moveOut ? 'translate-y-full' : 'translate-y-0'}`}></div>
            <h2 className={`text-white absolute text-9xl transition-transform duration-[500ms] ${moveOut ? 'opacity-0' : 'opacity-100'}`}>{AllLetters[count]}</h2>
        </div>
    );
};

export default Loading;
