import { ReactComponent as Logo } from "../assets/ap-logo.svg";
import { Link } from "react-router-dom";
import { MdKeyboardBackspace } from "react-icons/md";
import { ReactComponent as ApLogo } from "../assets/ap-logo.svg";


export const WorkNavbar = ({ setCircleSize }) => {
    return (
        <div className="w-full min-h-[150px] border-b-2 border-neutral-600 flex items-center px-12 justify-between"
        >
            <Link to="/"
                onMouseEnter={() => setCircleSize(2)}
                onMouseLeave={() => setCircleSize(1)}
                onClick={() => setCircleSize(1)}
            >
                <MdKeyboardBackspace size={70} />
            </Link>
            <div>
                <ApLogo onMouseEnter={() => setCircleSize(2)}
                    onMouseLeave={() => setCircleSize(1)} width="85" height="85" />
            </div>
        </div>
    )
}
