import { useEffect, useRef, useState } from "react";
import Matter from "matter-js";

export function Name() {
  const sceneRef = useRef(null);
  const engineRef = useRef(null);
  const renderRef = useRef(null);
  const letterRefs = useRef([]);
  const [isMobile, setIsMobile] = useState(false);

  // Check if device is mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    // Initial check
    checkMobile();
    
    // Add resize listener
    window.addEventListener('resize', checkMobile);
    
    // Cleanup
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (!sceneRef.current) return;

    const { Engine, Render, World, Bodies, Composites, Mouse, MouseConstraint } = Matter;

    const engine = Engine.create({ gravity: { x: 0, y: 1.5 } });
    engineRef.current = engine;

    // Adjust canvas size based on screen width
    const canvasWidth = isMobile ? window.innerWidth : 1300;
    const canvasHeight = isMobile ? 600 : 1000;

    const render = Render.create({
      element: sceneRef.current,
      engine: engine,
      options: { 
        width: canvasWidth, 
        height: canvasHeight, 
        wireframes: false, 
        background: "transparent" 
      },
    });
    renderRef.current = render;

    // Adjust spacing and sizing for mobile
    const startX = isMobile ? 50 : 220;
    const startY = -50;
    const letterSpacing = isMobile ? 60 : 200;
    const radius = isMobile ? 30 : 80;

    // Create ropes with different lengths connecting to the center of spheres
    const createRope = (x, y, segmentsCount = 20, endBody) => {
      const rope = Composites.stack(x, y, segmentsCount, 1, 5, 5, (px, py) => {
        return Bodies.circle(px, py, 5, {
          density: 0.001,
          frictionAir: 0.2,
          render: { fillStyle: "transparent" },
        });
      });

      // Constraints for the rope
      Composites.chain(rope, 0, 0, 0, 0, {
        stiffness: 1,
        length: 14,
        damping:1,
        render: { type: "line", strokeStyle: "white", lineWidth: isMobile ? 2 : 3 },
      });

      // Additional constraints for stiffness
      Composites.chain(rope, 0, 0, 0, 0, {
        stiffness: 1,
        length: 14,
        damping:1,
        render: { visible: true },
      });

      Composites.chain(rope, 0, 0, 0, 0, {
        stiffness: 1,
        length: 14,
        damping:1,
        render: { visible: true },
      });

      Composites.chain(rope, 0, 0, 0, 0, {
        stiffness: 1,
        length: 14,
        damping:1,
        render: { visible: true },
      });

      Composites.chain(rope, 0, 0, 0, 0, {
        stiffness: 1,
        length: 14,
        damping:1,
        render: { visible: true },
      });

      // Top constraint positioned above the screen
      const topConstraint = Matter.Constraint.create({
        pointA: { x, y },
        bodyB: rope.bodies[0],
        stiffness: 1,
        length: 0,
        damping: 1,
        render: { visible: false },
      });

      // Connect to the center of the letter body
      const endConstraint = Matter.Constraint.create({
        bodyA: rope.bodies[rope.bodies.length - 1],
        bodyB: endBody,
        pointB: { x: 0, y: 0 }, // Connect to center of the sphere
        stiffness: 0.3,
        length: 20,
        damping: 1,
        render: { visible: false },
      });

      return { rope, constraints: [topConstraint, endConstraint] };
    };

    // Define different rope lengths with starting points above screen
    // Scale down for mobile but increase length by 25%
    const getLetterHeights = () => {
      if (isMobile) {
        return [
          { segments: 19, yOffset: 187 },  // First A (15 * 1.25 = 18.75 ≈ 19)
          { segments: 23, yOffset: 225 },  // A (18 * 1.25 = 22.5 ≈ 23)
          { segments: 18, yOffset: 162 },  // K (14 * 1.25 = 17.5 ≈ 18)
          { segments: 20, yOffset: 200 },  // A (16 * 1.25 = 20)
          { segments: 24, yOffset: 237 },  // S (19 * 1.25 = 23.75 ≈ 24)
          { segments: 21, yOffset: 212 },  // H (17 * 1.25 = 21.25 ≈ 21)
        ];
      } else {
        return [
          { segments: 20, yOffset: 370 },  // First A - longer rope
          { segments: 24, yOffset: 440 },  // A - longer rope
          { segments: 18, yOffset: 330 },  // K - medium rope
          { segments: 22, yOffset: 390 },  // A - longer rope
          { segments: 26, yOffset: 470 },  // S - longest rope
          { segments: 21, yOffset: 380 },  // H - medium-long rope
        ];
      }
    };

    const letterHeights = getLetterHeights();

    const letters = "AAKASH".split("").map((char, index) => {
      const x = startX + index * letterSpacing;
      
      // Get the height configuration for this letter
      const { segments, yOffset } = letterHeights[index];
      
      const letterBody = Bodies.circle(x, startY + yOffset, radius, {
        restitution: 0.2,
        friction: 0.1,
        density: 0.002,
        frictionAir: 0.001,
        render: { 
          fillStyle: "rgba(255, 255, 255, 0)", // Completely transparent spheres
          strokeStyle: "rgba(0, 0, 0, 0)", 
          lineWidth: 0
        },
      });

      // Create rope with the specified number of segments
      const rope = createRope(x, startY, segments, letterBody);

      return {
        char,
        body: letterBody,
        ropes: [rope.rope],
        constraints: [...rope.constraints],
      };
    });

    const mouse = Mouse.create(render.canvas);
    const mouseConstraint = MouseConstraint.create(engine, {
      mouse: mouse,
      constraint: { stiffness: 0.05, render: { visible: false } },
    });

    World.add(engine.world, [
      ...letters.flatMap(({ body, ropes, constraints }) => [body, ...ropes, ...constraints]),
      mouseConstraint,
    ]);

    const runner = Matter.Runner.create();
    Matter.Runner.run(runner, engine);
    Matter.Render.run(render);

    const updateLetters = () => {
      letters.forEach(({ body }, index) => {
        if (letterRefs.current[index]) {
          // Adjust positioning based on device
          const letterSize = isMobile ? 80 : 200;
          const offset = letterSize / 2;
          letterRefs.current[index].style.transform = `translate(${body.position.x - offset}px, ${body.position.y - offset}px) rotate(${body.angle}rad)`;
        }
      });
      requestAnimationFrame(updateLetters);
    };
    updateLetters();

    return () => {
      Matter.Render.stop(render);
      Matter.World.clear(engine.world, false);
      Matter.Engine.clear(engine);
      Matter.Runner.stop(runner);
      render.canvas.remove();
      render.textures = {};
    };
  }, [isMobile]);

  // Determine letter size based on device
  const letterSize = isMobile ? 70 : 200;

  return (
    <div 
      style={{ 
        position: "relative", 
        width: "100%", 
        maxWidth: 1300, 
        height: isMobile ? 600 : 1200, 
        background: "transparent", 
        zIndex: 80,
        overflow: "hidden"
      }}
    >
      <div ref={sceneRef} style={{ position: "absolute", top: "50px", left: 0 }}></div>
      {"AAKASH".split("").map((char, index) => (
        <div
          key={index}
          ref={(el) => (letterRefs.current[index] = el)}
          style={{
            position: "absolute",
            width: letterSize,
            height: letterSize,
            textAlign: "center",
            fontSize: `${letterSize}px`,
            fontWeight: "",
            color: "white",
            lineHeight: `${letterSize}px`,
            borderRadius: "50%",
            background: "transparent",
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "Anton",
            left: 0,
          }}
        >
          {char}
        </div>
      ))}
    </div>
  );
}

export default Name;