import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";

const projects = [
    {
        name: "Zenith",
        sourceCode: "https://github.com/aakashahpl/stock-broker",
        demoLink: "https://stock-broker-tau.vercel.app/",
        imageSrc: "/portfolio/stockBroker.png",
        about: {
            line1: "Stock Broker application that allows users to access stock data, execute buy/sell transactions, and receive real-time stock price updates and business news.",
            line2: ""
        }
    },
    {
        name: "Blog Website",
        sourceCode: "https://github.com/aakashahpl/ang-blogWebsite",
        demoLink: "https://blog-app-blue-xi.vercel.app/",
        imageSrc: "/portfolio/blog-website.png",
        about: {
            line1: "Developed server side of the application focusing on user management, blogs and comment handling ",
            line2: " - Utilized PassportJs, JWT for secure user management, and Multer for handling file uploads (multipart/form-data)."
        }
    },  
    {
        name: "Eco Bin",
        sourceCode: "https://github.com/aakashahpl/indiaTourism",
        demoLink: "",
        imageSrc: "/portfolio/eco-bin.png",
        about: {
            line1: "A smart waste management system for municipalites and collection vehicles for efficient waste collection and seggregation.",
            line2: ""
        }
    },
    {
        name: "Skill Forge",
        sourceCode: "https://github.com/aakashahpl/Skill-forge",
        demoLink: "",
        imageSrc: "/portfolio/skillForge.jpg",
        about: {
            line1: "Educational web app that allows users to generate personalized programming courses using Google's Gemini API.",
            line2: " - Integrated course creation features based on custom prompts for any programming concept or language."
        }
    },
];

const Projects = ({ setCircleSize }) => {
    const [imageIndex, setImageIndex] = useState(0);
    const [distortionLevel, setDistortionLevel] = useState(0);
    const lastScrollY = useRef(0);
    const animationFrameId = useRef(null);
    const resetTimeoutId = useRef(null);
    const isScrolling = useRef(false);
    const projectRefs = useRef([]);
    const mobileProjectRefs = useRef([]);

    useEffect(() => {
        // Create an intersection observer for desktop project titles
        const desktopObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        // Get the index from the data attribute
                        const index = parseInt(entry.target.dataset.index);
                        setImageIndex(index);
                    }
                });
            },
            {
                // Root is viewport
                root: null,
                // Trigger when the middle of the element hits the middle of the viewport
                rootMargin: "-50% 0px -50% 0px",
                threshold: 0
            }
        );

        // Observe all desktop project title elements
        projectRefs.current.forEach((ref) => {
            if (ref) {
                desktopObserver.observe(ref);
            }
        });

        // Create a separate observer for mobile project sections
        const mobileObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        // Get the index from the data attribute
                        const index = parseInt(entry.target.dataset.index);
                        setImageIndex(index);
                    }
                });
            },
            {
                root: null,
                rootMargin: "-40% 0px -40% 0px",
                threshold: 0.3
            }
        );

        // Observe all mobile project sections
        mobileProjectRefs.current.forEach((ref) => {
            if (ref) {
                mobileObserver.observe(ref);
            }
        });

        return () => {
            projectRefs.current.forEach((ref) => {
                if (ref) {
                    desktopObserver.unobserve(ref);
                }
            });
            
            mobileProjectRefs.current.forEach((ref) => {
                if (ref) {
                    mobileObserver.unobserve(ref);
                }
            });
        };
    }, []);

    useEffect(() => {
        let lastScrollTime = 0;
        const updateDistortion = (timestamp) => {
            const currentScrollY = window.scrollY;
            const scrollDiff = Math.abs(currentScrollY - lastScrollY.current);

            if (scrollDiff > 0) {
                const timeDiff = timestamp - lastScrollTime;
                const scrollSpeed = scrollDiff / timeDiff;

                const newDistortion = Math.min(scrollSpeed * 50, 1);
                setDistortionLevel(newDistortion);
                lastScrollTime = timestamp;
                isScrolling.current = true;

                if (resetTimeoutId.current) {
                    clearTimeout(resetTimeoutId.current);
                }
            } else if (isScrolling.current) {
                resetTimeoutId.current = setTimeout(() => {
                    setDistortionLevel(0);
                    isScrolling.current = false;
                }, 210);
            }

            lastScrollY.current = currentScrollY;
            animationFrameId.current = requestAnimationFrame(updateDistortion);
        };

        animationFrameId.current = requestAnimationFrame(updateDistortion);

        return () => {
            if (animationFrameId.current) {
                cancelAnimationFrame(animationFrameId.current);
            }
            if (resetTimeoutId.current) {
                clearTimeout(resetTimeoutId.current);
            }
        };
    }, []);

    const getImageStyle = () => {
        return {
            filter: `blur(${distortionLevel * 2}px) brightness(${1 - distortionLevel * 0.2})`,
            transition: 'filter 0.3s ease-out',
            willChange: 'filter',
        };
    };

    return (
        <>
            <motion.div 
                initial={{ x: -300, opacity: 0 }} 
                whileInView={{ x: 0, opacity: 1 }} 
                transition={{ duration: 2, type: 'spring', delay: 0.5 }} 
                viewport={{ once: true }}
                className="w-11/12 text-5xl md:text-7xl lg:min-h-[300px] lg:text-[10rem] uppercase font-coign justify-start px-5 lg:px-44 align-middle items-center tracking-wide text-neutral-300"
            >
                Recent Work
            </motion.div>
            
            {/* Desktop Layout */}
            <div className="hidden md:flex flex-row min-h-screen">
                <div className="w-2/5 items-end justify-center flex flex-col">
                    {projects.map((item, index) => (
                        <div 
                            key={index} 
                            className="h-screen flex items-end justify-center flex-col w-full"
                        >
                            <div className="px-5 lg:w-4/5 flex flex-col items-start gap-6 -mr-32">
                                <h1 
                                    ref={el => projectRefs.current[index] = el}
                                    data-index={index}
                                    className="text-[2.5rem] lg:text-[4.5rem] w-full flex justify-start font-semibold text-neutral-300 z-30 uppercase"
                                >
                                    {item.name}
                                </h1>
                                <h2 className="text-base lg:text-xl flex justify-end w-10/12 z-30 text-neutral-300">
                                    {item.about.line1}
                                </h2>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="w-3/5 z-10 relative">
                    <div className="flex items-center h-[100vh] sticky top-0">
                        <img
                            src={projects[imageIndex].imageSrc}
                            className="lg:w-10/12 rounded-md"
                            style={getImageStyle()}
                            alt={projects[imageIndex].name}
                        />
                    </div>
                </div>
            </div>
            
            {/* Mobile Layout */}
            <div className="md:hidden flex flex-col">
                {projects.map((item, index) => (
                    <div 
                        key={index}
                        ref={el => mobileProjectRefs.current[index] = el}
                        data-index={index}
                        className="min-h-screen flex flex-col items-center justify-center py-16 px-4"
                    >
                        <div className="w-full mb-8">
                            <img
                                src={item.imageSrc}
                                className="w-full rounded-md"
                                style={getImageStyle()}
                                alt={item.name}
                            />
                        </div>
                        <div className="w-full flex flex-col items-start gap-4">
                            <h1 
                                className="text-3xl sm:text-4xl w-full flex justify-start font-semibold text-neutral-300 z-30 uppercase"
                            >
                                {item.name}
                            </h1>
                            <h2 className="text-sm sm:text-base z-30 text-neutral-300">
                                {item.about.line1}
                            </h2>
                            <div className="flex gap-4 mt-4">
                                {item.sourceCode && (
                                    <a 
                                        href={item.sourceCode} 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                        className="text-neutral-300 border border-neutral-300 px-3 py-1 rounded hover:bg-neutral-700 transition-colors"
                                    >
                                        Source Code
                                    </a>
                                )}
                                {item.demoLink && (
                                    <a 
                                        href={item.demoLink} 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                        className="text-neutral-300 border border-neutral-300 px-3 py-1 rounded hover:bg-neutral-700 transition-colors"
                                    >
                                        Live Demo
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default Projects;