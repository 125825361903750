import React, { useEffect, useRef } from "react";
// import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { RxSlash } from "react-icons/rx";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";
import ContactButton from "./ui/ContactButton";
import { ReactComponent as Signature } from "../assets/ak_sig.svg";



gsap.registerPlugin(ScrollTrigger);

const Contact = () => {
    const titleRef2 = useRef(null);
    const emailRef = useRef(null);

    useEffect(() => {
        // Title animation
        // eslint-disable-next-line
        const titleSplit = new SplitType(titleRef2.current, { types: "chars" });
        gsap.set(titleRef2.current.querySelectorAll(".char"), { y: 50, opacity: 0 });
        gsap.to(titleRef2.current.querySelectorAll(".char"), {
            scrollTrigger: {
                trigger: titleRef2.current,
                start: "top 70%",
                toggleActions: "play none none none",
                invalidateOnRefresh: true,
            },
            y: 0,
            opacity: 1,
            stagger: 0.02,
            duration: 0.6,
            ease: "power3.out",
            delay: 2,
        });

        // Email animation
        // eslint-disable-next-line
        const emailSplit = new SplitType(emailRef.current, { types: "chars" });
        gsap.set(emailRef.current.querySelectorAll(".char"), { y: 50, opacity: 0 });
        gsap.to(emailRef.current.querySelectorAll(".char"), {
            scrollTrigger: {
                trigger: emailRef.current,
                start: "top 70%",
                toggleActions: "play none none none"
            },
            y: 0,
            opacity: 1,
            stagger: 0.02,
            duration: 0.9,
            ease: "power3.out",
            delay: 0.3 // Slight delay after title animation
        });
    }, []);

    return (
        <div
            name="contact"
            className="w-full min-h-screen bg-[#b8bab9] text-gray-100 flex justify-center items-center flex-col lg:flex-col mx-auto z-50 relative"
        >
            <div className="flex flex-col md:flex-row px-2">
                <div className="lg:w-8/12 justify-between flex flex-col gap-12">
                    <div className="mt-10 lg:mt-60 flex flex-col">
                        <h1
                            ref={titleRef2}
                            className="text-6xl lg:text-[9rem] font-extrabold font-coign tracking-wide"
                        >
                            INTERESTED IN WORKING TOGETHER?
                        </h1>
                        <p className="flex flex-col lg:flex-row gap-6">
                            <h2
                                ref={emailRef}
                                className="text-xl lg:text-4xl mt-2 font-mulish"
                            >
                                aakashpatel315@gmail.com
                            </h2>
                            <ContactButton />
                        </p>
                    </div>
                </div>
                <img src="/lucky-cat.jpg" draggable="false" alt="lucky-cat" className="w-64 lg:w-auto mx-auto" />
            </div>
            <div className="flex w-full h-24 justify-center items-center px-2">
                <div className="flex hover:text-neutral-400 font-semibold text-sm items-center text-white h-fit lg:tracking-[0.2rem] lg:ml-24">
                    <a
                        href="https://linkedin.com/in/aakashahpl"
                        className="hover:text-neutral-100 transition-colors duration-300"
                        target="_blank"
                    >
                        LINKEDIN
                    </a>
                    <RxSlash size={14} className="mx-1 lg:mx-2" />
                    <a
                        href="https://github.com/aakashahpl"
                        className="hover:text-neutral-100 transition-colors duration-300"
                        target="_blank"
                    >
                        GITHUB
                    </a>
                    <RxSlash size={14} className="mx-1 lg:mx-2" />
                    <a
                        href="https://github.com/aakashahpl"
                        className="hover:text-neutral-100 transition-colors duration-300"
                        target="_blank"
                    >
                        INSTAGRAM
                    </a>
                    <RxSlash size={14} className="mx-1 lg:mx-2" />
                    {/* <a
                        href="https://drive.google.com/file/d/1gMk07c-T0uRreueUQQzkS7zVtL9vT2sN/view?usp=drive_link"
                        className="hover:text-neutral-100 transition-colors duration-300"
                        target="_blank"
                    >
                        RESUME
                    </a> */}
                </div>
                <div className="border w-full mx-5 hidden md:block"></div>
                {/* <Signature className=" rotate-12"/>  */}
                <div className="hidden md:block font-light text-sm px-4 w-96">
                    Developed by Aakash Patel<br/>
                    @2025 All Rights Reserved
                </div>
            </div>
        </div>
    );
};

export default Contact; 