import React, { useEffect } from "react";
import Contact from "./Contact";
import { ReactLenis, useLenis } from 'lenis/react';
import { WorkNavbar } from "./WorkNavbar";
import Projects from "./Projects";

const Work = ({ setCircleSize }) => {
  const lenis = useLenis();

  return (
    <ReactLenis
      options={{
        duration: 1, // Duration of the scroll effect
        smoothTouch: true, // Enable smooth scrolling on touch devices
        direction: "vertical", // Scroll direction
      }} root>
      <div className="bg-[#1a1a1a] min-h-screen w-full justify-center items-center text-neutral-200 font-mulish">
        <WorkNavbar setCircleSize={setCircleSize} />
        <Projects setCircleSize={setCircleSize} />
        <Contact />
      </div>
    </ReactLenis>
  );
};

export default Work;
