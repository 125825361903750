import React from "react";
import { useEffect, useRef, useState } from "react";
import { HiArrowDownRight } from "react-icons/hi2";
import { motion } from "framer-motion";
import { gsap } from "gsap";
import SplitType from "split-type";
import { ReactComponent as ApLogo } from "../assets/ap-logo.svg";
import Name from "./Name";

const Home = ({ setCircleSize }) => {
  const textRef = useRef(null);
  const textRef2 = useRef(null);
  const textRef3 = useRef(null);
  const svgRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    // Text animation
    /* eslint-disable */
    const split = new SplitType(textRef.current, { types: "chars" });
    const split2 = new SplitType(textRef2.current, { types: "chars" });
    const split3 = new SplitType(textRef3.current, { types: "chars" });
    /* eslint-enable */

    // Set initial state for text
    gsap.set(".char", { y: 50, opacity: 0 });

    // Text animation - store reference
    const textAnimation = gsap.to(".char", {
      y: 0,
      opacity: 1,
      stagger: 0.02,
      delay: 3.8,
      duration: 0.4,
      ease: "power3.out",
    });

    // Logo animation
    const paths = svgRef.current.querySelectorAll('path');

    // Set initial state for both paths
    paths.forEach(path => {
      const length = path.getTotalLength();
      gsap.set(path, {
        strokeDasharray: length,
        strokeDashoffset: length,
        fill: 'transparent',
        stroke: 'white',
        strokeWidth: 2
      });
    });

    // Create timeline for simultaneous animation
    const tl = gsap.timeline({ delay: 3.8 });

    // Animate both paths simultaneously
    tl
      .to(paths, {
        strokeDashoffset: 0,
        duration: 1.2,
        ease: "power2.out"
      })
      .to(paths, {
        fill: 'white',
        duration: 0.3,
        ease: "power2.inOut"
      }, "+=0.05");

    // Return cleanup function
    return () => {
      // Kill all animations
      textAnimation.kill();
      tl.kill();

      // Revert the split text
      if (split) split.revert();
      if (split2) split2.revert();
      if (split3) split3.revert();
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div
      name="home"
      className="h-screen w-full bg-[#1a1a1a] z-10"
    >
      <div
        className="flex flex-row gap-2 fixed z-[70] left-16 top-10 mix-blend-difference cursor-pointer"
        onMouseEnter={() => setCircleSize(2)}
        onMouseLeave={() => setCircleSize(1)}
        onClick={scrollToTop}
      >
        {isMobile?(<ApLogo ref={svgRef} width="60" height="60" />):(<ApLogo ref={svgRef} width="85" height="85" />)}
      </div>
      <div className="flex flex-col items-center justify-center h-full w-full mx-auto ">
        <div className="h-auto flex flex-col lg:flex-row justify-start items-center w-full">
          {isMobile ? (
            <div className="text-white home-bg-text flex flex-row items-center justify-center gap-[1px]">
              AAKASH PATEL
            </div>
          ) : (<Name />)}

          <div className={`text-neutral-300 flex justify-end font-mulish ${isMobile? "w-full pr-8":""}`}>
            <div className={!isMobile?`mt-20`:""}>
              <h3 className="text-md md:text-[1.6rem] flex items-center gap-2">
                <HiArrowDownRight />
                <div
                  ref={textRef}
                >
                  FULL STACK DEVELOPER
                </div>
              </h3>
              <h3 className="text-md md:text-[1.6rem] ml-9" ref={textRef2}>
                BASED IN INDIA
              </h3>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-row justify-between px-14 mt-6">
          <div className="flex flex-row gap-4"></div>
        </div>

        <div></div>
      </div>
    </div>
  );
};

export default Home;